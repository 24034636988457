.App {
  text-align: center;
}

.MuiSvgIcon-root {
  height: 40px !important;
  color: rgba(0, 0, 0, 0.26);
}

.MuiSlider-markLabel {
  font-weight: 300;
}
